import tinySlider from "../tiny-slider/tiny-slider";

export default class Advantages {
  constructor() {
    this.advantages = document.querySelector(".advantages");
    this.advantagesList = this.advantages.querySelector(".advantages__sliders");
    this.sliderControls = this.advantages.querySelector(".slider__controls");
    this.configSlider = {
      container: this.advantagesList,
      controlsContainer: this.sliderControls,
      items: 1,
      nav: false,
      loop: true,
      rewind: true,
      speed: 500,
      autoplayTimeout: 5000,
      responsive: {
        768: {
          autoplay: true
        }
      }
    };
  }

  init() {
    const slider = tinySlider(this.configSlider);
    this.slider = slider;
  }
}
