import LazyLinePainter from "../../../node_modules/lazy-line-painter/lib/lazy-line-painter-1.9.6";

export default class Header {
  constructor() {
    this.planarSvg = document.querySelector("#highlight-lines");
    this.highlightStrokeAll = this.planarSvg.querySelectorAll("[data-llp-id]");
    this.configAnimation = {
      repeat: -1
    };
  }

  removeStroke() {
    [...this.highlightStrokeAll].map(item => item.removeAttribute("style"));
  }

  init() {
    if (this.planarSvg) {
      if (
        navigator.userAgent.indexOf("MSIE") != -1 ||
        !!document.documentMode == true ||
        window.navigator.userAgent.indexOf("Edge") > -1
      ) {
        this.removeStroke();
      } else {
        const planarAnimation = new LazyLinePainter(
          this.planarSvg,
          this.configAnimation
        );
        planarAnimation.paint();
      }
    }
  }
}
