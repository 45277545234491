'use strict';

// Polyfill
import 'babel-polyfill/dist/polyfill.min';
import '../../node_modules/intersection-observer/intersection-observer';
import '../../node_modules/smooth-scroll/dist/smooth-scroll.polyfills';

// Plugins
import sal from '../../node_modules/sal.js/dist/sal';

import Header from '../_modules/header/header';
import Portal from '../_modules/portal/portal';
import Advantages from '../_modules/advantages/advantages';
import Tabs from '../_modules/tabs/tabs';
import Producer from '../_modules/producer/producer';
import Button from '../_modules/button/button';
import TopNav from '../_modules/top-nav/top-nav';
import Distributor from '../_modules/distributor/distributor';
import Customers from '../_modules/customers/customers';

class Main {
  constructor() {
    this.header = document.querySelector('.header');
    this.portal = document.querySelector('.portal');
    this.advantages = document.querySelector('.advantages');
    this.tabs = document.querySelector('.tabs');
    this.producer = document.querySelector('.producer');
    this.button = document.querySelector('.button');
    this.aboutPlanar = document.querySelector('.about-planar');
    this.topNav = document.querySelector('.top-nav');
    this.distributor = document.querySelector('.distributor');
    this.customers = document.querySelector('.customers');
    this.overlay = document.querySelector('.overlay');
    this.configSal = {
      threshold: 0.5,
      once: true
    };
    this.configSmoothScroll = {
      mousewheelSensitivity: 8
    };
  }

  init() {
    // Animation UI
    if (window.innerWidth >= 768) {
      sal(this.configSal);
    }
    else {
      const scrollAnimations = sal();
      scrollAnimations.disable();
    }

    if (this.header) {
      const header = new Header();
      header.init();
    }

    if (this.portal) {
      const portal = new Portal(this.overlay);
      portal.init();
    }

    if (this.advantages) {
      const advantages = new Advantages();
      advantages.init();
    }

    if (this.tabs) {
      const tabs = new Tabs();
      tabs.init();
    }

    if (this.producer) {
      const producer = new Producer();
      producer.init();
    }

    if (this.button) {
      const button = new Button();
      button.init();
    }

    if (this.topNav) {
      const topNav = new TopNav();
      topNav.init();
    }

    if (this.distributor) {
      const sliders = new Distributor();
      sliders.init();
    }

    if (this.customers) {
      const customers = new Customers();
      customers.init();
    }
  }
}

document.addEventListener('DOMContentLoaded', () => new Main().init());
