export default class Button {
  constructor() {
    this.buttonRippleAll = document.querySelectorAll(".button--ripple");
  }

  mapButtonRipple() {
    [...this.buttonRippleAll].map(button => {
      var rippleBox = document.createElement("span");
      rippleBox.setAttribute("class", "ripple-box");
      button.appendChild(rippleBox);

      button.addEventListener("mousedown", this.rippleEffect);
    });
  }

  rippleEffect(event) {
    const box = this.lastElementChild,
      create = document.createElement("span"),
      // Getting the button's size, distance to top and left
      boxWidth = box.offsetWidth,
      boxHeight = box.offsetHeight,
      boxY = box.getBoundingClientRect().top,
      boxX = box.getBoundingClientRect().left,
      // Getting the mouse position
      mouseX = event.clientX,
      mouseY = event.clientY,
      // Mouse position relative to the box
      rippleX = mouseX - boxX,
      rippleY = mouseY - boxY,
      // Calculate which is the farthest corner
      rippleWidth = boxWidth / 2 < rippleX ? rippleX : boxWidth - rippleX,
      rippleHeight = boxHeight / 2 < rippleY ? rippleY : boxHeight - rippleY,
      // Distance to the farest corner
      boxSize = Math.sqrt(Math.pow(rippleWidth, 2) + Math.pow(rippleHeight, 2)),
      // Getting the button computed style
      thisStyle = window.getComputedStyle(this);
    // Creating and moving the effect div inside the button

    box.appendChild(create);

    // Ripple style (size, position, color and border-radius)
    const colorName = create
      .closest(".button--ripple")
      .getAttribute("data-ripple-color");

    create.setAttribute("class", `ripple-effect ${colorName}`);
    create.style.height = 2 * boxSize + "px";
    create.style.width = 2 * boxSize + "px";
    create.style.top = mouseY - boxY - boxSize + "px";
    create.style.left = mouseX - boxX - boxSize + "px";
    box.style.borderTopLeftRadius = thisStyle.getPropertyValue(
      "border-top-left-radius"
    );
    box.style.borderTopRightRadius = thisStyle.getPropertyValue(
      "border-top-right-radius"
    );
    box.style.borderBottomLeftRadius = thisStyle.getPropertyValue(
      "border-bottom-left-radius"
    );
    box.style.borderBottomRightRadius = thisStyle.getPropertyValue(
      "border-bottom-right-radius"
    );

    setTimeout(function() {
      box.removeChild(create);
    }, 1000);
  }

  init() {
    if (this.buttonRippleAll) {
      this.mapButtonRipple();
    }
  }
}
