import tinySlider from "../tiny-slider/tiny-slider";

export default class Tabs {
  constructor() {
    this.tabItemAll = document.querySelectorAll(".tabs__tab-item");
    this.tabContentAll = document.querySelectorAll(".tabs__content-item");
    this.tabList = document.querySelector(".tabs__tab-list");
    this.contentList = document.querySelector(".tabs__content-list");
    this.configSlider = {
      autoWidth: true,
      loop: false,
      mouseDrag: true,
      container: this.tabList,
      controls: false,
      swipeAngle: false,
      speed: 400,
      nav: false,
      autoPlay: false,
      cancelable: false,
      gutter: 16,
      responsive: {
        1440: {
          disable: true,
          gutter: 0
        }
      }
    };
    this.slider;
  }

  getNumberSlide(currentItem) {
    const slideNumber = Number(currentItem.getAttribute("id").match(/\d+/g)[1]);
    this.slider.goTo(slideNumber);
  }

  removeActiveTab() {
    [...this.tabItemAll].map(tab => {
      tab.classList.remove("tabs__tab-item--active");
    });
    [...this.tabContentAll].map(tab => {
      tab.classList.remove("tabs__content-item--active");
    });
  }

  activeTabContent(tab) {
    const tabId = tab.dataset.tab;
    this.contentList
      .querySelector(tabId)
      .classList.add("tabs__content-item--active");
  }

  activeTabHandle() {
    this.tabList.addEventListener("click", e => {
      const target = e.target.closest(".tabs__tab-item");

      target
        ? (this.removeActiveTab(),
          this.activeTabContent(target),
          this.getNumberSlide(target),
          target.classList.add("tabs__tab-item--active"))
        : e.preventDefault();
    });
  }

  init() {
    if (this.tabList) {
      this.activeTabHandle();
    }

    const slider = tinySlider(this.configSlider);
    this.slider = slider;
  }
}
