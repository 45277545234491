import maskInput from "../../../node_modules/vanilla-text-mask/dist/vanillaTextMask";

export default class Portal {
  constructor(overlay) {
    this.portal = document.querySelector(".portal");
    this.portalClose = this.portal.querySelector(".close-button");
    this.inputAll = document.querySelectorAll(".form__input");
    this.textareaAll = document.querySelectorAll(".form__textarea");
    this.phoneInput = document.getElementById("phone");
    this.buttonPartnerAll = document.querySelectorAll(".button--partner");
    this.overlay = overlay;
    this.body = document.body;
    this.form = document.querySelector(".form");
    this.formName = document.getElementById("name");
    this.formEmail = document.getElementById("email");
    this.formPhone = document.getElementById("phone");
    this.formText = document.getElementById("text");
    this.formSuccess = document.querySelector(".form-success");
  }

  focusFieldHandle(element) {
    element.addEventListener("focus", () => element.classList.add("focus"));
  }

  blurFieldHandle(element) {
    element.addEventListener(
      "blur",
      () => element.value.length === 0 && element.classList.remove("focus")
    );
  }

  fieldMap(target) {
    [...target].map(element => {
      this.focusFieldHandle(element);
      this.blurFieldHandle(element);
    });
  }

  handlePortalClose() {
    this.overlay.classList.remove("overlay--visible");
    this.portal.classList.remove("portal--visible");
    this.form.classList.remove("form--hidden");
    this.formSuccess.classList.remove("form-success--visible");
    this.form.reset();
    this.removeFocus();
  }

  removeFocus() {
    [...this.form.querySelectorAll(".form__field")].map(field => {
      field.firstElementChild.classList.remove("focus");
    });
  }

  phoneMask() {
    return maskInput({
      inputElement: this.phoneInput,
      mask: [
        "+",
        "7",
        " ",
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]
    });
  }

  mapButtonPartner() {
    [...this.buttonPartnerAll].map(button => this.buttonPartnerHandle(button));
  }

  buttonPartnerHandle(button) {
    button.addEventListener("click", () => {
      this.overlay.classList.add("overlay--visible");
      this.portal.classList.add("portal--visible");
    });
  }

  successForm() {
    this.form.classList.add("form--hidden");
    this.formSuccess.classList.add("form-success--visible");
  }

  sendFormData() {
    const data = new URLSearchParams();
    data.append("name", this.formName.value);
    data.append("email", this.formEmail.value);
    data.append("phone", this.formPhone.value);
    data.append("text", this.formText.value);

    fetch("sendForm.php", {
      method: "POST",
      body: data
    }).then(response => {
      if (response.status === 200) {
        this.successForm();
      } else {
        this.successForm();
        this.formSuccess.innerHTML =
          "Что то пошло не так, повторите попытку позже!";
      }
    });
  }

  init() {
    if (this.inputAll) {
      this.fieldMap(this.inputAll);
    }

    if (this.inputAll) {
      this.fieldMap(this.textareaAll);
    }

    if (this.portalClose) {
      this.portalClose.addEventListener("click", () =>
        this.handlePortalClose()
      );
    }

    if (this.overlay) {
      this.overlay.addEventListener("click", () => this.handlePortalClose());
    }

    if (this.phoneInput) {
      this.phoneMask();
    }

    if (this.buttonPartnerAll) {
      this.mapButtonPartner();
    }

    if (this.form) {
      this.form.addEventListener("submit", e => {
        e.preventDefault();
        this.sendFormData();
      });
    }
  }
}
