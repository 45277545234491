import SmoothScroll from "../../../node_modules/smooth-scroll/dist/smooth-scroll";

export default class TopNav {
  constructor() {
    this.topNav = document.querySelector(".top-nav");
    this.burger = this.topNav.querySelector(".burger");
    this.nav = document.querySelector(".nav");
    this.navList = document.querySelector(".nav__list");
    this.navItemAll = document.querySelectorAll(".nav__item");
    this.navLinkAll = document.querySelectorAll(".nav__link");
    this.navSectionAll = document.querySelectorAll(".nav-section");
    this.body = document.body;
    this.navigationHeight = 80;
    this.configSmoothScroll = {
      speed: 800,
      speedAsDuration: true,
      offset: () => {
        if (window.innerWidth < 1440) {
          return 25;
        } else {
          return 0;
        }
      }
    };
  }

  removeActiveNav() {
    [...this.navItemAll].map(item => {
      item.classList.remove("nav__item--active");
    });
  }

  navigationFixedHandle() {
    window.addEventListener("scroll", () => {
      if (
        (window.pageYOffset || document.documentElement.scrollTop) >=
        this.navigationHeight / 2
      ) {
        this.topNav.classList.add("is-fixed");
      } else {
        this.topNav.classList.remove("is-fixed");
      }
    });
  }

  toggleBurger() {
    this.burger.classList.toggle("burger--close");
    this.nav.classList.toggle("nav--open");
    this.body.classList.toggle("scroll-lock");
  }

  burgerHandle() {
    this.burger.addEventListener("click", () => {
      this.toggleBurger();
    });
  }

  navListHandle() {
    this.navList.addEventListener("click", e => {
      const target = e.target.closest(".nav__item");
      if (target) {
        this.removeActiveNav();
        target.classList.add("nav__item--active");
        window.innerWidth < 1440 && this.toggleBurger();
      } else {
        e.preventDefault();
      }
    });
  }

  afterRefreshPage() {
    if (window.location.hash) {
      if (window.history.replaceState) {
        window.history.replaceState("", "/", window.location.pathname);
      } else {
        window.location.hash = "";
      }
    }
    window.addEventListener("beforeunload", () => {
      window.scrollTo(0, 0);
    });
  }

  windowScrollHandle(section, i) {
    window.addEventListener("scroll", () => {
      const scrollDistance = window.pageYOffset;
      if (section.offsetTop <= scrollDistance) {
        this.removeActiveNav();
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          this.navLinkAll[4].parentElement.classList.add("nav__item--active");
        } else {
          this.navLinkAll[i].parentElement.classList.add("nav__item--active");
        }
      }
    });
  }

  mapNavSection() {
    [...this.navSectionAll].map((section, i) => {
      this.windowScrollHandle(section, i);
    });
  }

  init() {
    this.afterRefreshPage();

    if (this.topNav) {
      if (window.innerWidth >= 1440) {
        this.navigationFixedHandle();
      }
    }

    if (this.burger) {
      this.burgerHandle();
    }

    if (this.navList) {
      this.navListHandle();
      new SmoothScroll('a[href*="#"]', this.configSmoothScroll);
    }

    if (this.navSectionAll) {
      this.mapNavSection();
    }
  }
}
