import tinySlider from '../tiny-slider/tiny-slider';

export default class Producer {
  constructor() {
    this.producer = document.querySelector('.producer');
    this.producerList = document.querySelector('.producer__list');
    this.producerItemAll = document.querySelectorAll('.producer__item');
    this.sliderControls = this.producer.querySelector('.slider__controls');
    this.configSlider = {
      container: this.producerList,
      controlsContainer: this.sliderControls,
      loop: false,
      nav: false,
      items: 2,
      axis: 'vertical',
      swipeAngle: false,
      speed: 400
    };
    this.producerRow;
  }

  removeAllItemToList() {
    while (this.producerList.firstChild) {
      this.producerList.removeChild(this.producerList.firstChild);
    }
  }

  mapProducerItem(countItems) {
    this.removeAllItemToList();
    this.producerList.setAttribute('class', 'producer__list');

    [...this.producerItemAll].map((item, i) => {
      this.checkItems(item, i, countItems);
    });
  }

  checkItems(item, i, countItems) {
    if (i % countItems === 0) {
      this.producerRow = document.createElement('div');
      this.producerRow.setAttribute('class', 'producer__row');
      this.producerRow.appendChild(item);
      this.producerList.appendChild(this.producerRow);
    }
    else {
      this.producerRow.appendChild(item);
    }
  }

  checkWindowWidth() {
    if (window.innerWidth >= 1440) {
      this.mapProducerItem(5);
    }
    else if (window.innerWidth >= 768) {
      this.mapProducerItem(2);
    }
    else if (window.innerWidth >= 320) {
      this.mapProducerItem(1);
    }
  }

  init() {
    if (this.producerItemAll) {
      this.checkWindowWidth();
      window.addEventListener('resize', () => {
        this.checkWindowWidth();
      });
    }

    tinySlider(this.configSlider);
  }
}
