import tinySlider from '../tiny-slider/tiny-slider';

export default class Customers {
  constructor() {
    this.distributorList = document.querySelector('.customers__list');
    this.config = {
      autoWidth: true,
      container: this.distributorList,
      controls: false,
      items: 1,
      nav: false,
      loop: false,
      gutter: 16,
      mouseDrag: true,
      swipeAngle: false,
      cancelable: false,
      responsive: {
        1440: {
          disable: true
        }
      }
    };
    this.slider;
  }

  getNumberSlide(currentItem) {
    const slideNumber = Number(currentItem.getAttribute('id').match(/\d+/g)[1]);
    this.slider.goTo(slideNumber);
  }

  init() {
    if (this.distributorList && window.innerWidth < 1440) {
      this.slider = tinySlider(this.config);;

      this.distributorList.addEventListener('click', e => {
        const target = e.target.closest('.customers__item');
        target
          ? this.getNumberSlide(target)
          : e.preventDefault();
      });
    }
  }
}